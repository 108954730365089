import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";

const EditCompany = (props) => {
  const { companyInfo, onReload } = props;

  return (
    <div>
      <FormDefault
        tittle="Editar datos de la compañía"
        fields={[
          {
            label: "Id de compañia",
            name: "Num",
            placeholder: "Introduzca id de compañia (opcional)",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Número de Identificación Tributaria (NIT)",
            name: "Nit",
            placeholder: "Introduzca su numero NIT",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Cedula de identidad",
            name: "Ci",
            placeholder: "Introduzca su ci",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Razon social",
            name: "RazonSocial",
            placeholder: "Introdusca la Razon Social",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Correo electrónico",
            name: "Email",
            placeholder: "Introdusca el correo electrónico",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Categoria",
            name: "categoria",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "AA",
                value: "AA",
              },
              {
                label: "A",
                value: "A",
              },
              {
                label: "B",
                value: "B",
              },
              {
                label: "C",
                value: "C",
              },
            ],
          },
          {
            label: "Teléfono",
            name: "Phone",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Dirección",
            name: "Direccion",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Gps",
            name: "Gps",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Nombre del representante legal",
            name: "LegalRepresentativeName",
            placeholder: "Introduzca el código del cliente",
          },
          {
            label: "CI del representante legal",
            name: "LegalRepresentativeCI",
            placeholder: "Introduzca el código del cliente",
          },
          {
            label: "Emisión del CI del representante legal",
            name: "LegalRepresentativeCIEmision",
            placeholder: "Introduzca el código del cliente",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Santa Cruz",
                value: "Santa Cruz",
              },
              {
                label: "La Paz",
                value: "La Paz",
              },
              {
                label: "Cochabamba",
                value: "Cochabamba",
              },
              {
                label: "Chuquisaca",
                value: "Chuquisaca",
              },
              {
                label: "Oruro",
                value: "Oruro",
              },
              {
                label: "Tarija",
                value: "Tarija",
              },
              {
                label: "Beni",
                value: "Beni",
              },
              {
                label: "Pando",
                value: "Pando",
              },
              {
                label: "Potosí",
                value: "Potosí",
              },
              {
                label: "QR",
                value: "QR",
              },
            ],
          },
          {
            label: "Observación",
            name: "details",
            type: "textarea",
            typeInput: "textarea",
          },
        ]}
        initialValues={{
          Id: companyInfo.id ?? "",
          Num: companyInfo.num ?? "",
          Nit: companyInfo.nit ?? "",
          Ci: companyInfo.ci ?? "",
          RazonSocial: companyInfo.razonSocial ?? "",
          Email: companyInfo.email ?? "",
          categoria: companyInfo.categoria ?? "",
          Phone: companyInfo.phone ?? "",
          Direccion: companyInfo.direccion ?? "",
          Gps: companyInfo.gps ?? "",
          LegalRepresentativeName: companyInfo.legalRepresentativeName ?? "",
          LegalRepresentativeCI: companyInfo.legalRepresentativeCI ?? "",
          LegalRepresentativeCIEmision:
            companyInfo.legalRepresentativeCIEmision ?? "",
          details: companyInfo.details ?? "",
        }}
        validationSchema={Yup.object().shape({
          Nit: Yup.string().required("Este campo es obligatorio"),
          RazonSocial: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("put", "company", values)
            .then(() => {
              onReload();
              toast.success("Envio exitoso");
            })
            .catch(() => {
              toast.error("Se ha producido un error al enviar");
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
};

export default EditCompany;
