import CallServiceTable from "../../../components/Tables/CallServiceTable";
import Button from "../../../components/Buttons/Button";
import { useModal } from "../../../contexts/ModalContext";
import { useDialog } from "../../../contexts/DialogContext";
import CompanyDetail from "../../../containers/menu/manageCompany/CompanyDetail";
import CreateCompany from "../../../containers/menu/manageCompany/CreateCompany";
import ImportClient from "../../../containers/menu/manageCompany/ImportClient";
import RenderPDFClient from "../../../containers/menu/manageCompany/RenderPDFClient";
import { validateArray } from "../../../utils/validate";

const documentosRequeridos = [
  "NIT",
  "CEDULA DE IDENTIDAD REPRESENTANTE LEGAL",
  "PODER - REPRESENTANTE LEGAL",
  "SEPREC",
  "COMPROMISO DE SEGURIDAD o CERTIFICACION OEA",
];

const ManageCompany = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function handleImport(reload) {
    openModal(<ImportClient reload={reload} onClose={onClose} />);
  }

  function handleNewCompany(reload) {
    openModal(<CreateCompany reload={reload} onClose={onClose} />);
  }

  function handleGetCompany(company, reload) {
    openModal(
      <CompanyDetail companyID={company.id} reload={reload} onClose={onClose} />
    );
  }

  // function handleDocumentPdf(data) {
  //   openDialog(<RenderPDFClient company={data} />);
  // }

  function handleDocumentPdf(data) {
    openDialog(<RenderPDFClient company={data} dialogClose={dialogClose} />);
  }

  function validarDocumentos(documentosRequeridos, files) {
    const documentosFaltantes = [];
    let documentosCompletos = true;

    documentosRequeridos.forEach((doc) => {
      if (doc === "COMPROMISO DE SEGURIDAD o CERTIFICACION OEA") {
        const tieneCompromiso = files.some(
          (file) => file.name.trim() === "COMPROMISO DE SEGURIDAD"
        );
        const tieneCertificacion = files.some(
          (file) => file.name.trim() === "CERTIFICACION OEA"
        );

        if (!tieneCompromiso && !tieneCertificacion) {
          documentosFaltantes.push(doc);
          documentosCompletos = false;
        }
      } else {
        const encontrado = files.some((file) => file.name.trim() === doc);
        if (!encontrado) {
          documentosFaltantes.push(doc);
          documentosCompletos = false;
        }
      }
    });

    return {
      documentosCompletos,
      documentosFaltantes,
    };
  }

  return (
    <CallServiceTable
      onClick={handleGetCompany}
      urlApi="/company"
      component={({ reload }) => {
        return (
          <>
            <Button onClick={() => handleImport(reload)}>
              Importar <i className="fas fa-cloud-download-alt"></i>
            </Button>
            <Button onClick={() => handleNewCompany(reload)}>
              Crear compañía <i className="fa-solid fa-plus"></i>
            </Button>
          </>
        );
      }}
      header={[
        {
          name: "razonSocial",
          label: "Compañía",
          filter: true,
        },
        {
          name: "files",
          label: "Documentos",
          type: "custom",
          builder: (value) => {
            const documents = validarDocumentos(documentosRequeridos, value);
            return (
              <div
                className={`relative inline-block group text-white w-fit ${
                  documents.documentosCompletos
                    ? "bg-[#4fce4431] text-gray-500"
                    : "bg-[#fd3838A1]"
                } text-center rounded-full py-1 px-4 cursor-pointer`}
              >
                {documents.documentosCompletos ? "Completo" : "Incompleto"}
                <div class="absolute -z-0 left-full -bottom-6 ml-2 hidden group-hover:block bg-gray-800 text-white text-sm p-2 rounded-lg whitespace-nowrap">
                  <div className="flex flex-col items-start">
                    {value.map((item, index) => {
                      return (
                        <p key={index} className={`text-green-500`}>
                          <i class="fas fa-check"></i> {item.name}
                        </p>
                      );
                    })}
                    {documents.documentosFaltantes.map((item) => {
                      return (
                        <p key={item} className={`text-red-500`}>
                          <i class="fas fa-times"></i> {item}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          name: "nit",
          label: "NIT",
          filter: true,
        },
        {
          name: "categoria",
          label: "Categoria",
          filter: false,
        },
        {
          name: "status",
          label: "Estado",
          filter: false,
        },
        {
          name: "Reports",
          type: "action",
          label: "Reporte",
          actions: [
            {
              label: "Imprimir datos del cliente",
              icon: "fas fa-print",
              action: handleDocumentPdf,
              color: "text-[#1d4ed8] pl-3",
            },
          ],
        },
      ]}
      filters={[
        {
          name: "category",
          default: "Todos las categorías",
          options: [
            {
              label: "AA",
              name: "AA",
            },
            {
              label: "A",
              name: "A",
            },
            {
              label: "B",
              name: "B",
            },
            {
              label: "C",
              name: "C",
            },
          ],
        },
      ]}
      addUrl={`userId=${localStorage.getItem("id")}`}
      showTable={false}
    />
  );
};

export default ManageCompany;
