// import './RenderPdf.css'
import * as dayjs from "dayjs";
import { Request } from "../../../hooks/Request";
import logotipo from "../../../assets/Logadex.jpg";
import { Circle } from "../../../components/Animation";
// import { requestAuth } from '../../components/services/RequestService'
import View from "../../../components/GeneratePdf/View";
import Text from "../../../components/GeneratePdf/Text";
import CustomTablePDF from "../../../components/GeneratePdf/CustomTablePDF";
// import GeneratePdf from '../../components/GeneratePdf/GeneratePdf'
import { Page, Document, PDFViewer, Image, Link } from "@react-pdf/renderer";
import { MessageUnsigned } from "../../../components/Forms/FormMessages";
import { validateArray } from "../../../utils/validate";

const legalDocuments = [
  "NIT",
  "CEDULA DE IDENTIDAD REPRESENTANTE LEGAL",
  "PODER - REPRESENTANTE LEGAL",
  "SEPREC",
  "OTROS",
];

const otherDocuments = [
  "FP-08-03 ACUERDO COMERCIAL O CONTRATO",
  "CERTIFICACIÓN OEA - F-08-09",
];

const RenderPDFClient = (props) => {
  const { company } = props;

  const { data, loading } = Request({
    urlApi: `/company/${company.id}`,
  });
  // const { data: dataContacts, loading: loadingContacts } = Request({
  //   urlApi: `/company/${company.id}/contacts`,
  // });
  // const { data: dataInChargce, loading: loadingInCharge } = Request({
  //   urlApi: `/company/${company.id}/inChargeCompany `,
  // });
  const { data: dataCompany, loading: loadingCompany } = Request({
    urlApi: `company/${company.id}/details`,
  });

  if (!loading || !loadingCompany) {
    return <Circle />;
  }

  if (!data) {
    return null;
  }

  function handleListDetail(detail) {
    const auxList = detail ? JSON.parse(detail) : [];
    return auxList.map((item) =>
      item.value ? <Text>- {item.value}</Text> : null
    );
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent>
          <View
            width="15%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            style={{ padding: "5px" }}
          >
            <Image src={logotipo} style={{ height: "50px" }} />
          </View>
          <View
            width="70%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="10px"
          >
            <Text textAlign="center" fontSize="11px" bold>
              CHECK LIST - DOCUMENTOS LEGALES - CLIENTE
            </Text>
          </View>
          <View width="15%" flexDirection="column" justifyContent="center">
            <Text textAlign="center" bold>
              F-08-03
            </Text>
            <Text textAlign="center" bold>
              REV.: 00
            </Text>
          </View>
        </View>
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#e5e5e5" }}
        >
          <Text bold>I. Datos del Cliente</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Nombre del cliente:
          </Text>
          <Text>{data.razonSocial}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            NIT:
          </Text>
          <Text>{data.nit}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Correo Electronico:
          </Text>
          <Text>{data.email}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Telefono:
          </Text>
          <Text>{data.phone}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Fecha de creación:
          </Text>
          <Text>{dayjs(data.creationDate).format("DD/MM/YYYY HH:mm")}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Dirección:
          </Text>
          <Text maxWidth="420px">{data.direccion}</Text>
        </View>
        {data.gps ? (
          <View border={{ right: true, bottom: true, left: true }}>
            <Text bold minWidth="130px" border={{ right: true }}>
              Ubicación:
            </Text>
            <Text>
              <Link src={data.gps}>ubicación</Link>
            </Text>
          </View>
        ) : null}
        {validateArray(dataCompany)
          ? dataCompany.map((item) => {
              if (item.value !== "[]") {
                return (
                  <View
                    alignItems="center"
                    border={{ right: true, bottom: true, left: true }}
                  >
                    <Text bold minWidth="130px" maxWidth="130px">
                      {item.label}
                    </Text>
                    <View flexDirection="col" border={{ left: true }}>
                      {handleListDetail(item.value)}
                    </View>
                  </View>
                );
              } else {
                return null;
              }
            })
          : null}
        <View flexDirection="column" style={{ marginTop: "10px" }}>
          <View border width="100%">
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`A)`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"40%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`SOLICITUD DE DOCUMENTOS LEGALES`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`SI`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`NO`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"20%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`FECHA VIGENCIA`}
              </Text>
            </View>
            <View
              border={{ top: true, right: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`FECHA`}
              </Text>
            </View>
          </View>
        </View>
        {legalDocuments.map((item, index) => {
          const doc =
            data.files.find((file) => file.name.trim() === item) ?? null;
          return (
            <View key={index} flexDirection="column">
              <View border width="100%">
                <View
                  border={{}}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {` `}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"5%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {index + 1}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"35%"}
                  style={{}}
                  bold
                >
                  {doc ? (
                    <Link src={doc.url}>
                      <Text bold textAlign="center" color="#1d4ed8">
                        {doc.name}
                      </Text>
                    </Link>
                  ) : (
                    <Text bold textAlign="center">
                      {item}
                    </Text>
                  )}
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {doc ? `X` : " "}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {!doc ? `X` : " "}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"20%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {doc?.expirationDate
                      ? dayjs(doc.expirationDate).format("DD/MM/YYYY")
                      : " "}
                  </Text>
                </View>
                <View
                  border={{ right: true, bottom: true, left: true }}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {doc?.creationDate
                      ? dayjs(doc.creationDate).format("DD/MM/YYYY")
                      : " "}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}

        <View flexDirection="column" style={{ marginTop: "10px" }}>
          <View border width="100%">
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`B)`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"40%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`DOCUMENTOS - OTROS`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`SI`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`NO`}
              </Text>
            </View>
            <View
              border={{ top: true, bottom: true, left: true }}
              width={"20%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`FECHA VIGENCIA`}
              </Text>
            </View>
            <View
              border={{ top: true, right: true, bottom: true, left: true }}
              width={"10%"}
              justifyContent="center"
              style={{ backgroundColor: "#e5e5e5" }}
              bold
            >
              <Text bold textAlign="center">
                {`FECHA`}
              </Text>
            </View>
          </View>
        </View>
        {otherDocuments.map((item, index) => {
          const doc =
            data.files.find((file) => file.name.trim() === item) ?? null;
          return (
            <View key={index} flexDirection="column">
              <View border width="100%">
                <View
                  border={{}}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {` `}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"5%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {index + 1}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"35%"}
                  style={{}}
                  bold
                >
                  {doc ? (
                    <Link src={doc.url}>
                      <Text bold textAlign="center" color="#1d4ed8">
                        {doc.name}
                      </Text>
                    </Link>
                  ) : (
                    <Text bold textAlign="center">
                      {item}
                    </Text>
                  )}
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {doc ? `X` : " "}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {!doc ? `X` : " "}
                  </Text>
                </View>
                <View
                  border={{ bottom: true, left: true }}
                  width={"20%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {doc?.expirationDate
                      ? dayjs(doc.expirationDate).format("DD/MM/YYYY")
                      : " "}
                  </Text>
                </View>
                <View
                  border={{ right: true, bottom: true, left: true }}
                  width={"10%"}
                  justifyContent="center"
                  style={{}}
                  bold
                >
                  <Text bold textAlign="center">
                    {doc?.creationDate
                      ? dayjs(doc.creationDate).format("DD/MM/YYYY")
                      : " "}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
        <View flexDirection="column" style={{ marginTop: "10px" }}>
          <View border width="100%">
            <View
              bold
              width={"15%"}
              alignItems="center"
              justifyContent="center"
              border={{ top: true, bottom: true, left: true }}
            >
              <Text bold textAlign="center">
                {`OBSERVACIONES:`}
              </Text>
            </View>
            <View
              bold
              width="85%"
              alignItems="center"
              style={{ maxWidth: "85%" }}
              border={{ top: true, bottom: true, left: true, right: true }}
            >
              <Text bold>{data.details}</Text>
            </View>
          </View>
        </View>
        {/* {Array.from({ length: 10 }, (_, i) => i + 1).map((item, index) => (
          <View flexDirection="column">
            <View border width="100%">
              <View
                border={{}}
                width={"15%"}
                justifyContent="center"
                style={{}}
                bold
              >
                <Text bold textAlign="center">
                  {` `}
                </Text>
              </View>
              <View
                border={{ right: true, bottom: true, left: true }}
                width={"85%"}
                justifyContent="center"
                style={{}}
                bold
              >
                <Text bold textAlign="center">
                  {` `}
                </Text>
              </View>
            </View>
          </View>
        ))} */}
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderPDFClient;
